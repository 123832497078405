<template>
  <div class="d-block my-auto mx-auto w-100">
    <div class="container">
      <div class="row text-center">
        <button
          class="btn btn-dark text-light position-fixed rounded-pill p-3"
          style="bottom: 2em; right: 2em; max-width: 4em; z-index: 999"
        >
          <i class="bi bi-map"></i>
        </button>
        <h1 class="text-dark-blue display-5 fw-bold">Specials Near You</h1>
        <div class="my-2">
          <div class="d-flex flex-column">
            <div class="fs-5">
              <span
                class="badge rounded-pill border border-dark bg-grayish text-dark mx-1"
                data-v-5ecde346=""
                role="button"
                ><i class="bi bi-watch" data-v-5ecde346=""></i> Happening
                Now</span
              ><span
                class="badge rounded-pill border border-dark bg-grayish text-dark mx-1"
                data-v-5ecde346=""
                role="button"
                ><i class="bi bi-cup-straw" data-v-5ecde346=""></i> Drinks</span
              ><span
                class="badge rounded-pill border border-dark bg-grayish text-dark mx-1"
                data-v-5ecde346=""
                role="button"
                ><i class="bi bi-egg-fried" data-v-5ecde346=""></i> Food</span
              ><span
                class="badge rounded-pill border border-dark bg-grayish text-dark mx-1"
                role="button"
                data-v-5ecde346=""
                ><i class="bi bi-shop" data-v-5ecde346=""></i> Events</span
              ><span
                class="badge rounded-pill border border-dark bg-grayish text-dark mx-1"
                role="button"
                data-v-5ecde346=""
                ><i class="bi bi-scooter" data-v-5ecde346=""></i> Nearby</span
              >
            </div>
          </div>
          <div class="fs-5">
            <span
              role="button"
              class="badge rounded-pill border border-dark bg-grayish text-dark mx-1"
              >Set Search Time...</span
            >
          </div>
          <!---->
        </div>
        <div class="col text-center">
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Maya</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;grill</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;tequilalounge</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;mexican</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=525 East Grace Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.04 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Mexican Grill And Tequila Lounge</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $8 Classic margaritas $6 draft beer and bottled beer
                          </span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">525 East Grace Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://mayarva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Julep's New Southern Cuisine</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;southern</span></span>
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=420 East Grace Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.08 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Award winning southern restaurant in downtown RVA</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 draft beers, glasses of wine and specialty
                            cocktails</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">420 East Grace Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://juleps.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Lillie Pearl</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary"> &nbsp;newamerican</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=416 East Grace Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.08 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>New American restaurant and bar in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $2 off drafts and $6 select glasses of wine</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">416 East Grace Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.lilliepearlrva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Fall Line Kitchen &amp; Bar</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;upscale</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;american</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=500 East Broad Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.09 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Upscale-casual American restaurant in Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 select drafts, $6 select glasses of wine, $7-9
                            select cocktails, $5-7 select appetizers</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">500 East Broad Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a href="" target="_blank" rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Wong Gonzalez</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;chinese</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;asianfusion</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;mexican</span
                    ></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=412 East Grace Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.09 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Mexican-Chinese fusion restaurant serving mouthwatering
                  dishes and featuring a full bar</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $1 off all beer, $1 off wine by the glass, $4 rails,
                            $6 Wong’s Margs</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Thirsty Thursdays</span
                          ><span class="text-muted"
                            >$5 • Margarita special</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Taco Tuesday</span
                          ><span class="text-muted">
                            50% off all tacos, dine in only</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">412 East Grace Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="http://www.wonggonzalez.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Fri Jun 02 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Hotel Greene</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;minigolf</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=508 East Franklin Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.1 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Bar and minigolf course situated in an old hotel</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Ambassador Hour</span
                          ><span class="text-muted">
                            $20 per person for a round of golf and an appetizer
                            sampler plate</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >508 East Franklin Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.hotelgreene.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Mon Jun 12 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >ML Steak Modern Chophouse</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;chophouse</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;jacksonward</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=328 East Broad Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.14 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Modern steakhouse in Jackson Ward</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $6 select appetizers, $2 off drafts, $6 glasses of
                            wine, $6 wells</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">328 East Broad Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.mlsteakrva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Casta's Rum Bar</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;rum</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;cuban</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=700 East Main Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.18 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Cuban rum bar in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 beer, $10 cocktails and frozens, $40
                            pitchers</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">700 East Main Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.castasrumbar.com/location/castas-richmond/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sat Jun 17 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Kabana Rooftop</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary">
                      &nbsp;mediterranean</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;rooftopbar</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=700 East Main Street 20th Floor, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.18 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Rooftop bar and restaurant serving Mediterranean food and
                  cocktails</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $4 drafts and house cocktails, $5 mojitos, $6 orange
                            crushes, $7 frozen drinks, half off appetizers</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Power Hour</span
                          ><span class="text-muted">$3 • highballs</span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >700 East Main Street 20th Floor, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.kabanarooftop.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sat Jun 17 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Chicano's Cocina Bar &amp; Grill</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;downtown</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;grill</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;mexican</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=523 East Main Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.19 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Mexican bar and grill in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 Mexican drafts, $5 12oz margarita, $4 house
                            tequila and house shots, $6 glasses of wine</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">523 East Main Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.chicanoscocina.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Capital Ale House</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary">
                      &nbsp;dogfriendlypatio</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;american</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;biergarten</span
                    ></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=623 East Main Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.2 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>American restaurant and bar in Downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $1 off draft beers, $1 off highballs, $2 off
                            featured wine, $3 off house specialty
                            cocktails</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Late Night Happy Hour</span
                          ><span class="text-muted">
                            Half-off appetizers and $5 off Daily Specials</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">623 East Main Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="http://www.capitalalehouse.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Wed Jun 14 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Ay Caramba Mexican Food</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;mexican</span></span>
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=204 East Grace Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.23 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Authentic Mexican cuisine at a fun, exciting environment</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $1 off signature margaritas and beer</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">204 East Grace Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.aycarambamexican.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">KOGI</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;korean</span></span>
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=325 North 2nd Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.25 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Restaurant serving authentic, creative Korean street eats in
                  the heart of Jackson Ward, RVA</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $7 ipa drafts, $6 pbr &amp; rail shot, $5 rail/wells
                            drink, $5 imports (rotating drafts, corona, sapporo,
                            modelo, cass light, kloud, yuengling, hard ciders,
                            heineken), $3 domestic beer (pbr), $3 korean wine
                            shooter, $1.50 soju shot</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">325 North 2nd Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://kogirva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Mon Jun 12 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Parterre Restaurant</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;southern</span></span>
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=100 East Franklin Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.31 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Southern restaurant in Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            Half off all draft beer and wine</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >100 East Franklin Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.trolleyhouseva.com/parterre"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Barcode</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;gaybar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;outdoorpatio</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;pooltable</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;lgbtqfriendly</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=6 East Grace Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.35 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Gay bar with a pool table and outdoor patio</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted"> $1 off all drinks</span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">6 East Grace Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.barcoderva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 11 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Tarrant's Cafe Downtown</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;american</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;downtownrichmond</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1 West Broad Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.37 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>American restaurant in the heart of downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $7.95 Cheese or Bianca pizza (+$0.50 per topping),
                            $2 off draft beer and glasses of wine, $2 off
                            cocktails, $4 highballs</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1 West Broad Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.tarrantsdowntown.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Mon Jun 12 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Irie Ting Jamaican Grill</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;grill</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;monroeward</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;jamaican</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=100 East Cary Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.38 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Jamaican grill and lounge in Monroe Ward</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            Beer, wine and cocktails $2 off</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">100 East Cary Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://irieting.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Main Street Steakhouse</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;seafood</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;downtown</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;steakhouse</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1112 East Main Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.39 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Seafood and Steakhouse in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $7-$8 house cocktails, $5 straight spirits, $3.50
                            select beers, $3.50-$6.50 select glasses of wine,
                            $10 select food items</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1112 East Main Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://mainstreetsteakhouserva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">NAMA - Indian Cuisine</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;downtown</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;indian</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=15 West Broad Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.39 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Rich and flavorful Indian cuisine right in the heart of
                  Richmond's Art District</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $3 off select cocktails, $2 off domestic beers and
                            select food specials</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">15 West Broad Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.namarva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Switch</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary"> &nbsp;cocktailbar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;popupbar</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;nightclub</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;themedbar</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=13 West Broad Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.39 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Basement nightclub located in the heart of the Art District
                  in Richmond</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Power Hour</span
                          ><span class="text-muted">
                            $5 mixed drinks and 16oz draft beers</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">13 West Broad Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://switchrva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Mon Jun 12 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Charlotte's Southern Deli</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;deli</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;downtown</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=200 South 10th Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.42 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Southern deli in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $2 off wine, $3 off domestic beers, $4 well drinks,
                            $5 margaritas, $6 Richmonder</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">200 South 10th Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.facebook.com/charlottesllc/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Fri Jun 16 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Native Plate</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;downtown</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;asianstreetfood</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1203 East Main Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.42 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Asian street food restaurant in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $1 off drafts, $5 select glasses of wine, $3 soju
                            shots, $3 off select cocktails, half off well
                            drinks, $5 Narragansett tall boys, $2 off select
                            appetizers</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1203 East Main Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.nativeplaterva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">The Pit and The Peel</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;juicebar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;healthyfood</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1051 East Cary Street # 104, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.43 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Health food bistro and juice bar</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $2 off appetizers, $2 off draft beer, $2 off glasses
                            of wine, $8 specialty cocktails</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >1051 East Cary Street # 104, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://thepitandthepeel.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sat Jun 17 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >The Tobacco Company Restaurant</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary">
                      &nbsp;cocktaillounge</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;dancefloor</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;livemusic</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;nightclub</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;restaurant</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1201 East Cary Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.45 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Four story restaurant with two distinct dining floors, a
                  cocktail lounge with live music, and a popular night club and
                  dance floor</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $2 off rail liquors, wines by the glass, and
                            beer</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1201 East Cary Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.thetobaccocompany.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Thu Jun 08 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Triple Crossing Beer - Downtown</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;brewery</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;outdoorpatio</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=113 South Foushee Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.46 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Brewery in downtown RVA</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0"
                            >Happy Hour - Beer and Shot</span
                          ><span class="text-muted"
                            >$8 • Falcon Smash &amp; Underberg</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >113 South Foushee Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://triplecrossing.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Fri Jun 16 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Sam Miller's</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;seafood</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;shockoeslip</span
                    ></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;steak</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1210 East Cary Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.47 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Seafood and steak restaurant located in ShockoeSlip </em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $2 off drafts, $5 house wine and wells, $1.75
                            oysters on the half shell and $4-$7 happy hour
                            snacks</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1210 East Cary Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="http://www.sammillers.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Thu Jun 08 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Bar Solita</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary"> &nbsp;restaurant</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;tapas</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=123 West Broad Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.48 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Bar and restaurant with a vacation vibe</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 well drinks and draft beers, $7 house wines and
                            classic cocktails, $10 frozen cocktails</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">123 West Broad Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://barsolita.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 13 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >The urban Farmhouse market &amp; cafe - Shockoe Slip</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;cafe</span></span>
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1217 East Cary Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.49 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Cafe in Shockoe Slip</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $2 off house wines, $1 off 12oz bottles and canned
                            beer</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1217 East Cary Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.theurbanfarmhouse.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">The Lobby Bar</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary"> &nbsp;appetizers</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;quirkhotel</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=201 West Broad Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.49 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Bar in the lobby of the Quirk Hotel</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            Select Signature drinks $8-9, select appetizers and
                            sharables $6-22</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">201 West Broad Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.quirkhotels.com/hotels/richmond/dining-richmond-va/the-lobby-bar"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 13 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">City Dogs Downtown</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;hotdogs</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1316 East Cary Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.52 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Hot dog restaurant and bar located in downtown RVA</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $2.50 domestic beer, $3 rails</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1316 East Cary Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.citydogsrva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Thu Jun 08 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Mom's Siam 2 Restaurant</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary"> &nbsp;asianfusion</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;thai</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;sushi</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1309 East Cary Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.53 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Asian fusion restaurant featuring a full sushi bar and an
                  open kitchen</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $6 mojitos, Mai Tais, mules, blueberry g+t, draft
                            beers, glasses of wine, select appetizers and sushi
                            rolls</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Saturday Happy Hour</span
                          ><span class="text-muted">
                            $3 select drafts, $6 select appetizers, sushi rolls,
                            glasses of wine, sake, and select cocktails
                          </span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1309 East Cary Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="http://momsiam2.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 06 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Casa Del Barco - Canal Walk</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;mexican</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=320 South 12th Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.55 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Mexican restaurant featuring regionally inspired Mexican
                  cuisine and innovative cocktails in Downtown RVA</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 nachos, guac, and queso, half off select
                            appetizers, draft beers, glasses of wine, and select
                            cocktails</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">320 South 12th Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.casadelbarcova.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 06 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Siné Irish Pub</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;nightclub</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;irishpub</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1327 East Cary Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.56 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Authentic Irish pub and restaurant</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Brunch</span
                          ><span class="text-muted">
                            $1 mimosas and poinsettias, $3 Bloody Marys, $5
                            mimosa, poinsettia, and sangria pitchers</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $3 domestic drafts and rails, $4 Fireball, Jager,
                            select seltzers, $5 craft beers, $6 select cocktails
                            or craft beer flights</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1327 East Cary Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.sineirishpub.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 06 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">GWARbar</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;gwarbar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;musicbar</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=217 West Clay Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.57 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Gwar themed bar</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $1.50 mystery beers, $2.50 Miller High-Life, $11
                            Narragansett Pitchers, $4 well drinks
                          </span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">217 West Clay Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://gwarbar.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Mon Jun 12 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Sprezza</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;italian</span></span>
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=111 Virginia Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.6 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Authentic food from the Puglia region in Italy</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 off house cocktails, $9 house glasses of wine,
                            $10 spritz, $5 beers</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">111 Virginia Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://sprezzacucina.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 06 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Southern Railway Taphouse</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;american</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;beer</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;patio</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;outdoorseating</span
                    ></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;taphouse</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=100 South 14th Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.61 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >American restaurant and bar featuring a lively weekend
                  atmosphere</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            Half off appetizers and signature cocktails, $3
                            rails and domestic beers, $4 select drafts, glasses
                            of wine, and white claws</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Burger Night</span
                          ><span class="text-muted"
                            >$10 • Classic burger and any draft beer</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">100 South 14th Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://srtaphouse.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Fri Jun 02 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >The Riviere ( The Honey Drizzle )</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;american</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=506 West Broad Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.68 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Bar and brunch spot serving American food in a relaxed
                  environment</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $3 rails, half off beer, $5 margaritas and Long
                            Island iced teas, $7 Hennessee and Patron drinks,
                            $10-$12 select food items</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">506 West Broad Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://theriviereva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Havana 59 Downtown</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;cuban</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;cigar</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;downtown</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=16 North 17th Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.71 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Cuban restaurant and cigar bar in downtown</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 original mojitos, Havana Libres, select glasses
                            of wine and red sangria, $2.50 Panga Drops
                            ponies</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">16 North 17th Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://havana59.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sat Jun 24 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Rosie Connolly's Pub Restaurant</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary">
                      &nbsp;shockoebottom</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;irishpub</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1548 East Main Street A, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.72 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Irish pub and restaurant in Shockoe Bottom</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $4.50 drafts, house wines and liquor</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1548 East Main Street A, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://rosieconnollys.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Tio Pablo</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;mexican</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;tacos</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;glutenfree</span
                    ></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1703 East Franklin Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.76 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Restaurant serving Mexican food in Shockoe Bottom</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted"
                            >$5 • Select beer, tequila shots, house margaritas,
                            and select food items</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >1703 East Franklin Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.tiopablotacos.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Dailymenu Restaurant</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;downtown</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;southern</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1706 East Main Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.77 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em
                  >Restaurant in downtown serving southern cuisine with street
                  flare</em
                >
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 select shots, $6-15 select food items, $5-8
                            select cocktails, $25 hookah
                          </span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Power Hour</span
                          ><span class="text-muted"> Half off drinks</span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1706 East Main Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.dailymenurva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sat Jun 24 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark"
                >Bellos Lounge &amp; Restaurant</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;african</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;downtownrichmond</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;caribbean</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1712 East Franklin Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.78 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Fusion African-Caribbean cuisine in Downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            20% off specialty drinks, 20% off appetizers, $3
                            rails</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >1712 East Franklin Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.belloslounge.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Aces Sports Lounge</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;sportsbar</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;tvs</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=110 North 18th Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.78 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Sports bar in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5 glasses of wine, $2 drafts, half off top shelf
                            spirits, $2 off select appetizers</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">110 North 18th Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.acessportsloungerva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sat Jun 24 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Bottoms Up Pizza</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary">
                      &nbsp;shockoebottom</span
                    ></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;pizza</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1700 Dock Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.79 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Pizza restaurant in Shockoe Bottom</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $3 well drinks, $1 off beer, $5.50 house red/white
                            wines, $8 mules</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1700 Dock Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://bottomsuppizza.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Thu Jun 08 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">JewFro</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;downtown</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;african</span
                    ></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;jewish</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1721 East Franklin Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.79 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Jewish and African fusion restaurant in downtown</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted"
                            >$7 • Old Fashioneds, daquiris, and French 75s</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >1721 East Franklin Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.jewfrorestaurant.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sat Jun 24 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Sumo San Restaurant</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;sushi</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;japanese</span
                    ></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bar</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1725 East Main Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.8 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Sushi restaurant in Shockoe Bottom</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $3 Kirin/Kirin Lite &amp; rails, $4 Jack
                            Daniels/Absolut, $5 red/white/plum wine
                            glasses</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1725 East Main Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="http://www.sumosanrva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Liquid 2.0</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;hookah</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;loungebar</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=4 North 18th Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.8 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Lounge and hookah bar in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            $5-$8 select spirits, $7 specialty drinks</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-success fs-5 fw-semibold"
                                >S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-success fs-5 fw-semibold"
                                >S</span
                              >
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><small
                            class="badge border rounded-pill border-limey text-limey mx-1"
                          >
                            Happening Now!
                          </small>
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Shot Special</span
                          ><span class="text-muted">$2 • shots</span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">4 North 18th Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.facebook.com/people/Liquid-20/100063628417357/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sat Jun 24 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Margaritas Cantina</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;bar</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;mexican</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=101-107 North 18th Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.81 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Mexican bar and restaurant in downtown Richmond</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Taco Tuesday</span
                          ><span class="text-muted"> $1+ tacos</span>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-muted fw-light">M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-muted fw-light">W</span
                              ><span class="text-muted fw-light">T</span
                              ><span class="text-muted fw-light">F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75"
                  >101-107 North 18th Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://margaritascantinarva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Tue Jun 20 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Stadium Sports Bar</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span><span class="text-primary"> &nbsp;sportsbar</span></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;tvs</span></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=119 North 18th Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.81 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>Sports bar offering craft cocktails and bar food</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            Most spirits half off, $2 off drafts, $1 off bottled
                            beer, half off wine, $2 off cocktails, select
                            appetizers $6-8</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">119 North 18th Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.stadiumsportsbarva.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Sun Jun 18 2023</span>
            </div>
          </div>
          <div class="card accordion my-3">
            <div
              class="accordion-header d-flex align-items-center justify-content-between bg-secondary rounded-1 px-4"
            >
              <span class="fw-bold fs-5 text-dark">Oak &amp; Apple BBQ</span
              ><button class="btn btn-primary position-relative my-3">
                <i class="bi bi-map text-light" style="z-index: 3"
                  ><span
                    class="position-absolute top-0 start-100 translate-middle border border-light badge rounded-pill fw-bold bg-success text-light"
                    >+<span class="visually-hidden">Add to map</span></span
                  ></i
                >
              </button>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-wrap">
                  <span
                    ><span class="text-primary">
                      &nbsp;dogfriendlypatio</span
                    ></span
                  ><span
                    ><span class="text-primary">&nbsp;• &nbsp;bbq</span></span
                  ><span
                    ><span class="text-primary"
                      >&nbsp;• &nbsp;barbacue</span
                    ></span
                  >
                </div>
                <div>
                  <a
                    href="https://www.google.com/maps/search/?api=1&amp;query=1814 East Main Street, Richmond"
                    ><small
                      class="text-muted fw-light d-flex flex-nowrap"
                      style="white-space: nowrap"
                      ><i class="bi bi-geo-alt"></i>0.84 mi
                    </small></a
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <em>BBQ restaurant in downtown RVA</em>
              </div>
              <div>
                <div class="accordion-body p-1">
                  <hr />
                  <div class="d-flex flex-column">
                    <div class="container g-0">
                      <div class="row">
                        <div
                          class="col-sm-12 col-md-8 d-flex flex-column justify-content-between align-center align-self-center"
                        >
                          <span class="h4 p-1 m-0">Happy Hour</span
                          ><span class="text-muted">
                            Half off 12oz drafts, $3 wells, $2 off glasses of
                            wine, $2 pork or chicken sliders, $7 baskets</span
                          >
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div>
                            <div class="mx-1">
                              <span class="text-muted fw-light">S</span
                              ><span class="text-success fs-5 fw-semibold"
                                >M</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >W</span
                              ><span class="text-success fs-5 fw-semibold"
                                >T</span
                              ><span class="text-success fs-5 fw-semibold"
                                >F</span
                              ><span class="text-muted fw-light">S</span>
                            </div>
                            <div class="d-flex flex-column">
                              <a
                                ><span class="text-primary"
                                  >Show/hide special hours...</span
                                ></a
                              ><!---->
                            </div>
                          </div>
                        </div>
                        <div class="inline text-muted">
                          <!----><!----><!----><!---->
                        </div>
                        <hr class="my-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer text-muted d-flex justify-content-between align-items-center"
            >
              <div>
                <span class="small w-75">1814 East Main Street, Richmond</span
                ><span class="w-25" style="white-space: nowrap"
                  ><i class="bi bi-link-45deg"></i
                  ><a
                    href="https://www.oakandapplerichmond.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  ></span
                >
              </div>
              <span class="small">Last verified: Thu Jun 08 2023</span>
            </div>
          </div>
          <!----><!----><!---->
        </div>
      </div>
    </div>
  </div>
</template>
